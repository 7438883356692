import $ from 'jquery'
import rivets from 'rivets'

rivets.configure({

    // Alias for index in rv-each binder
    iterationAlias: function (modelName) {
        return '%' + modelName + '%';
    },
})


$(document).ready(function () {

    var lastScrollTop = 0;
    $(window).scroll(function (event) {
        var st = $(this).scrollTop();
        if (st > lastScrollTop) {
            $('.navbar').fadeOut(150)
        } else {
            $('.navbar').fadeIn(150)
        }
        lastScrollTop = st;
    });

})




var list = [
    { image: 1, name: 'Walch Brigitte', attributes: ['52, Ledig', 'Hotelfachfrau', 'Gemeinderatsmitglied'] },
    { image: 2, name: 'Schlecht Alfons', attributes: ['62, Verheiratet, 2 Kinder', 'Lokführer', '2. Bürgermeister', '1. Vorstand Wählergruppe Einigkeit', '1. Vorstand KVSV Jesenwang / Pfaffenhofen', 'Kirchenpfleger'] },
    { image: 3, name: 'Wimmer Johann', attributes: ['67, Verheiratet, 5 Kinder', 'Bauunternehmer', 'Gemeinderatsmitglied', '2. Vorstand Wählergruppe Einigkeit'] },
    { image: 4, name: 'Hörhager Josef', attributes: ['55, Verheiratet, 3 Kinder', 'Bauhofleiter Gem. Jesenwang', 'Gemeinderatsmitglied', 'FFW Jesenwang Gruppenführer', 'Kassier Jagdgenossenschaft'] },
    { image: 5, name: 'Wörle Otto', attributes: ['57, Verheiratet, 4 Kinder', 'Ver.- u. Entsorger Abwasser', 'Gemeinderatsmitglied', 'FFW Jesenwang Feuerwehrmann'] },
    { image: 6, name: 'Winkler Florian', attributes: ['21, Ledig', 'FFW Jesenwang Feuerwehrmann'] },
    { image: 7, name: 'Winkler Corinna', attributes: ['42, Getrennt lebend, 2 Kinder', 'Dipl. Verwaltungswirtin (FH)', 'Schriftführerin Wählergruppe Einigkeit', 'Schulweghelferin'] },
    { image: 8, name: 'Magotsch Eva', attributes: ['64, Geschieden, 2 Kinder', 'Architektin', 'Gemeinderatsmitglied', 'Kirchenverwaltung', 'Kirchenchor', 'Schriftführerin Förderverein St. Georg'] },
    { image: 9, name: 'Burkhardt Tobias', attributes: ['27, Ledig', 'Fachinformatiker', 'FFW Jesenwang Feuerwehrmann', 'Theaterspieler KVSV / MGV'] },
    { image: 10, name: 'Hörhager Maria', attributes: ['22, Ledig', 'Staatlich anerkannte Erzieherin', 'Leitung Jugendgruppe', 'Übungshelferin Kinderturnen'] },
    { image: 11, name: 'Kolbe Tanja', attributes: ['41, Verheiratet, 2 Kinder', 'Hausfrau', 'Pfarrgemeinderatsmitglied', 'Schulweghelferin', 'Kassiererin Obst- u. Gartenbauverein'] },
    { image: 12, name: 'Schmid Christoph', attributes: ['36, Verheiratet, 2 Kinder', 'Oberbrandmeister', 'FFW Jesenwang Gruppenführer ', 'Atemschutzträger', 'TSV Jesenwang Abteilungsleiter', 'Fußball und Jugendtrainer'] },
    { image: 13, name: 'Philipp Bichl', attributes: ['23, Ledig', 'Student Sozial- u. Politikwissenschaften Univ.', 'Kassier Wählergruppe Einigkeit', 'FFW Pfaffenhofen Feuerwehrmann', 'TSV Jesenwang Fußballer'] },
    { image: 14, name: 'Göbel Alicia', attributes: ['22, Ledig', 'Disponentin, Berufsbegleitendes Bachelor Studium im Studiengang Business Administration'] },
    { image: 15, name: 'Heldwein Christian', attributes: ['48, Verheiratet, 1 Kind', 'Dipl. Informatiker, selbständig'] },
    { image: 16, name: 'Schmalhofer Matthias', attributes: ['38, Verheiratet, 1 Kind', 'Amper Perchten', 'MGV Jesenwang', 'TSV Jesenwang Fußballer'] },
    { image: 17, name: 'Eibl Josef', attributes: ['53, Verheiratet, 2 Kinder', 'Beamter', 'FFW Jesenwang Gruppenführer', 'Abt.- Leiter Schützen', 'Geschäftsführer Sonnendach GbR'] },
    { image: 18, name: 'Bauer Maximilian', attributes: ['63, Verheiratet, 1 Kind', 'Pilot'] },
    { image: 19, name: 'Skiljo Sejfo', attributes: ['43, Verheiratet, 2 Kinder', 'Regionalleiter', '2. Vorstand FFW Pfaffenhofen'] },
    { image: 20, name: 'Wybiral Dominik', attributes: ['34, Verheiratet', 'Hausmeister', '1. Kommandant FFW Pfaffenhofen'] },
    { image: 21, name: 'Probst Christl', attributes: ['57, Verheiratet, 2 Kinder', 'Baumarkt Verräumung', '2. Vorstand Stockschützen Pfaffenhofen', '2. Vorstand Förderverein St. Georg'] },
    { image: 22, name: 'Wybiral Sigi', attributes: ['59, Verheiratet, 2 Kinder', 'Psychologischer Assistent, Erzieher', '1.  Vorstand Förderverein St. Georg'] },
    { image: 23, name: 'Wörl Josef', attributes: ['64, Verheiratet, 2 Kinder', 'Fluglehrer', 'Fahnenträger Pro Luftfahrt', 'TSV Jesenwang Fußballfeld-Markierung'] },
    { image: 24, name: 'Gschwandtner Sandra ', attributes: ['34, Getrennt lebend, 1 Kind', 'Kodierfachkraft'] }
]

rivets.binders.src = function (el, value) {
    el.src = './img/kandidaten/' + value + '.jpg'
}

rivets.bind($('html'), { list: list })

var url = new URL(window.location.href)

if (url.searchParams.get('message')) {
    $('#messageSend')
        .show()
        .delay(3000)
        .fadeOut('300')
}

$('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
        // On-page links
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            // Figure out element to scroll to
            var target = $(this.hash)
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']')
            // Does a scroll target exist?
            if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault()
                $('html, body').animate(
                    {
                        scrollTop: target.offset().top
                    },
                    700
                )
                $('.navbar').fadeOut(150)
            }
        }
    })

$(document).ready(function () {

    // Check for click events on the navbar burger icon
    $(".navbar-burger").click(function () {

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        $(".navbar-burger").toggleClass("is-active");
        $(".navbar-menu").toggleClass("is-active");

    });
});
